<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>SAGE Sales</p>
            <div class="flex">
              <button @click="exportReport()" :class="firstLoading && listingArray.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 flex items-center">
                Export to Excel
              </button>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder relative max-w-min mt-2" v-if="firstLoading">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6-1 py-1 items-center" style="position: sticky;top: 0;background: white;">
              <div class="xsmall_col flex items-center" style="width:54px;!important">
                <span class="">Index</span>
              </div>
              <div class="small_col2  flex items-center" >
                <span class="cursor-pointer overFlowParaA" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallx_col  flex items-center" >
                <span class=" overFlowParaA" >{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center rightText" >
                <span class=" overFlowParaA">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center rightText" >
                <span class=" overFlowParaA">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center rightText" >
                <span class=" overFlowParaA">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center rightText" >
                <span class=" overFlowParaA">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div class="flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-start py-1 cursor-pointer hover:bg-gray-50" :class="{'bg-gray-100': indexA % 2}"
              v-for="(value, indexA) in listingArray" :key="indexA">
                <div class="xsmall_col">
                  <span class="heading-6-1 text-text1">{{indexA + 1}}</span>
                </div>
                <div class="small_col2">
                  <span class="heading-6-1 text-text1">{{value.itemCode}}</span>
                </div>
                <div>
                  <div v-for="(data, indexB) in value.itemSalesDetail" :key="indexB" class="flex">
                    <div class="smallx_col">
                      <span class="heading-6 text-text1 overFlowParaA">{{data.itemCodeDesc}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1">{{data.revenue | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.shipping | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.tax | amountFormaterWithToFix}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtySold}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="layout m-2 " v-if="listingArray.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center " role="alert" style="width: 1100px !important;">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'SAGE'" :callParent="'accounting'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    CustomDateSelector,
    GlobalFilter,
  },
  data () {
    return {
      drag: false,
      isFilterOpen: true,
      listingArray: [],
      titleObj: [
        {title: 'Item Code', icon: '', value: 'itemCode', sort: false},
        {title: 'Item Code Desc', icon: '', value: '', sort: true},
        {title: 'Revenue', icon: '', value: '', sort: true},
        {title: 'Shipping', icon: '', value: '', sort: true},
        {title: 'Tax', icon: '', value: '', sort: true},
        {title: 'Qty. Sold', icon: '', value: '', sort: true},
      ],
      pagination: {
        sortBy: 'itemCode',
        descending: true
      },
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    document.title = 'Accounting ◾ Dealers.PetStop.com'
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
      }
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.AccountingSageReportData(
        utcSTART,
        utcEND,
        false,
        response => {
          this.listingArray = response.Data === null ? [] : response.Data
          this.firstLoading = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          console.log(response.Data)
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.listingArray.sort(function (a, b) {
          if (val2 === 'itemCode') {
            var nameA = a.itemCode
            var nameB = b.itemCode
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.listingArray.reverse(function (a, b) {
          if (val2 === 'itemCode') {
            var nameA = a.itemCode
            var nameB = b.itemCode
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
      console.log('this.titleObj', this.listingArray)
    },
    async exportReport () {
      if (this.firstLoading && this.listingArray.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        let day1 = moment(this.filterObject.startDate).format("DD-MM-YY")
        let day2 = moment(this.filterObject.endDate).format("DD-MM-YY")
        let Time = 'Accounting Sage-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/production/sageSaleReport', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.vsmall_col {
  min-width: 94px !important;
  width: 94px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  min-width: 133px !important;
  width: 133px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell2_col {
  min-width: 138px !important;
  width: 138px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 174px !important;
  width: 174px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col2 {
  min-width: 240px !important;
  width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallx_col {
  min-width: 340px !important;
  width: 340px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.desc_col {
  min-width: 240px !important;
  width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

</style>
